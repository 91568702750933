import React, {useEffect, useState} from 'react';
import axios from 'axios';
import "./ScanLogin.css";
import {changeTitleType, closePage, eventReport, toast, userInfo} from "../../RubickJSBridge";
import pcimg from './pc.png';
import icon from '../../r_icon.png';
import {Button} from "@nutui/nutui-react";
import {_baseApiUrl} from "../../Config";

const LoginAuthPage = () => {
    const [session_id, setSessionId] = useState(null); // Session ID
    const [mUserInfo, setMUserInfo] = useState(null); // User info with token and uid
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false); // 防止重复点击的状态

    useEffect(() => {
        // 模拟获取 session_id，通常可以从 URL 参数中获取
        const urlParams = new URLSearchParams(window.location.search);
        const session = urlParams.get('session_id');
        setSessionId(session);

        changeTitleType(1);
        eventReport("pv","LoginAuthPage")



        userInfo((ui) => {
            setMUserInfo(ui);
            setLoading(false);
        });
    }, []);

    const handleConfirmLogin = async () => {
        if (isSubmitting) return; // 如果正在提交，则不处理点击

        if (!session_id || !mUserInfo) {
            alert('缺少必要信息，请重试。');
            return;
        }

        setIsSubmitting(true); // 设置提交状态，防止重复点击
        let url = `${_baseApiUrl}api/coreData/sLogin/confirm-login?session_id=${session_id}`;

        try {
            const response = await axios({
                method: 'POST', // 根据实际情况调整请求方法
                url: url,
                headers: {
                    'x-token': mUserInfo.token, // 替换为实际的 header 键值对
                    'x-uid': mUserInfo.uid, // 示例添加token
                }
            });
            if (response.data.code === 0) {
                toast('网页登录成功', "success");
                closePage()
            } else {
                toast(response.data.message ?? "登录失败", "error");
            }
        } catch (error) {
            toast(`登录失败: ${error}`, "error");
            console.error(error);
        } finally {
            setIsSubmitting(false); // 恢复按钮可点击状态
        }
    };

    // 检查页面加载是否完成
    if (loading) {
        return <div>加载中...</div>;
    }

    // 没有 session_id 或 token 时显示软件介绍界面
    if (!mUserInfo?.token) {
        return (
            <div className="intro-container">
                <img className="icon" src={icon} alt="icon"/>
                <h1>魔方信息</h1>
                <p>打造私人仪表盘 一眼阅世界脉络</p>
                <a href="https://apps.apple.com/app/id6447499774">
                    <Button className="mainBtn"> 去下载并安装应用</Button>
                </a>
            </div>
        );
    }

    // 有 session_id 和 token 时显示确认登录界面
    return (
        <div className="auth-container">
            <div className="icon-container">
                <img src={pcimg} className="computer-icon" alt="电脑图标"/>
            </div>
            <p>登录网页端</p>
            <Button className="mainBtn" onClick={handleConfirmLogin} disabled={isSubmitting}>
                {isSubmitting ? "登录中..." : "确认登录"}
            </Button>
        </div>
    );
};

export default LoginAuthPage;